<template>
  <div>
    <vx-card class="pb-10">
      <h2>{{ this.$t('Crear Tienda 1 a 1') }}</h2>
      <span>{{ this.$t('Ingresa datos de tu tienda') }}</span>

      <div class="vx-row mt-10">
        <!-- nombre -->
        <div class="vx-col w-1/2 sm:mb-5">
          <label for="name"><b>{{ this.$t('Nombre de Tienda') }}</b></label>

          <vx-input-group>
            <vs-input
              id="name"
              name="name"
              v-model="store.name"
              :danger="!!validate.nameError"
              val-icon-danger="clear"
              @keyup="validate.validateName(store.name)"
              autofocus
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
        </div>

        <!-- codigo -->
        <div class="vx-col w-1/2 sm:mb-5">
          <label for="code"><b>{{ this.$t('Código de Tienda') }}</b></label>

          <vx-input-group>
            <vs-input
              id="code"
              name="code"
              v-model="store.code"
              :danger="!!validate.skuError"
              val-icon-danger="clear"
              @keyup="validateStoreCode()"
              autofocus
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.skuError">{{ validate.skuError }}</p>
        </div>

        <!-- direccion -->
        <div class="vx-col w-1/2 lg:mt-5">
          <label for="address"><b>{{ this.$t('Dirección') }}</b></label>

          <vx-input-group>
            <vs-input
              id="address"
              name="address"
              :danger="!!validate.addressError"
              val-icon-danger="clear"
              v-model="store.address"
              placeholder="Ej. RUA QUINTINO BOCAIUVA, 1354"
              @change="validateAddress()"
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.addressError">{{ validate.addressError }}</p>
        </div>

        <!-- ciudad -->
        <div class="vx-col w-1/2 lg:mt-5">
          <label for="city"><b>{{ this.$t('Ciudad') }}</b></label>

          <v-select
            :options="cities"
            v-model="store.city_dane"
            :reduce="item => item.dane"
            label="name"
            id="city"
            :placeholder="this.$t('Seleccione una ciudad')"
          >
            <template slot="option" slot-scope="item">
              {{ item.name }}
            </template>

            <template slot="selected-option" slot-scope="item">
              {{ item.name }}
            </template>
          </v-select>
                    
          <p class="text-danger text-sm" v-if="!!validate.cityError">{{ validate.cityError }}</p>
        </div>

        <!-- cobertura  -->
        <div class="vx-col w-1/2 mt-5">
          <label for="influence"><b>{{ this.$t('Cobertura de envío') }}</b> (<i>km</i>)</label>

          <vx-input-group>
              <vs-input
                id="influence"
                name="influence"
                type="number"
                :danger="!!validate.numberError"
                @keyup="validate.validateNumber(store.influence)"
                @change="validate.validateNumber(store.influence)"
                v-model="store.influence"
              />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.numberError">{{ validate.numberError }}</p>
        </div>

        <!-- phone  -->
        <div class="vx-col w-1/2 mt-5">
          <label for="phone"><b>{{ this.$t('Teléfono') }}</b></label>

          <vx-input-group>
              <vs-input
                id="phone"
                name="phone"
                :danger="!!errs.phone"
                v-model="store.phone"
              />
          </vx-input-group>
          <p class="text-danger text-sm">{{ errs.phone }}</p>
        </div>

        <!-- precio minimo de compra -->
        <div class="vx-col w-1/2 mt-5">
          <label for="minimum_purchase"><b>{{ this.$t('Precio mínimo de compra') }}</b></label>

          <vx-input-group>
            <vs-input
              id="minimum_purchase"
              name="minimum_purchase"
              type="number"
              :danger="!!errs.minimum_purchase"
              v-model="store.minimum_purchase"
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!errs.minimum_purchase">{{ errs.minimum_purchase }}</p>
        </div>

        <!-- precio  -->
        <div class="vx-col w-1/2 mt-5">
          <label for="delivery_price"><b>{{ this.$t('Costo de envío') }}</b></label>

          <vx-input-group>
            <vs-input
              id="delivery_price"
              name="delivery_price"
              type="number"
              :danger="!!validate.nitError"
              v-model="store.delivery_price"
              @keyup="validate.validateNumber( store.delivery_price )"
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.numberError">{{ validate.numberError }}</p>
        </div>

        <!-- coordenadas  -->
        <div class="vx-col w-1/2 mt-5">
          <label for="coords"><b>{{ this.$t('Coordenadas') }}</b></label>

          <vx-input-group>
            <vs-input
              id="coords"
              name="coords"
              :danger="!!validate.coordsError"
              v-model="store.coords"
              @keyup="validate.validateCoords(store.coords)"
              @change="validate.validateCoords(store.coords)"
              readonly
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.coordsError">{{ validate.coordsError }}</p>
        </div>

        <!-- categoria -->
        <div class="vx-col w-1/2 mt-5">
          <label for="city"><b>{{ this.$t('Categoría de la tienda') }}</b></label>

          <v-select
            v-model="store.list_type_shops"
            :options="shopCategories"
            multiple
            label="name"
            :reduce="option => option.id"
            class="dropdown-menu-app"
            :placeholder="this.$t('Seleccione categorías')"
          >
            <template slot="option" slot-scope="option">
              {{ option.name }}
            </template>

            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <p class="text-danger text-sm" v-if="!!validate.userTypeError"> Seleccione una categoría </p>
        </div>

        <!-- mapa -->
        <div class="vx-col lg:w-1/2 sm:w-full mt-5 sm:mb-5">
          <label for="map"><b>{{ this.$t('Ver en Mapa') }}</b></label>

          <GmapMap
            :center="map.center"
            :zoom="15"
            style="height: 40vh"
            id="map"
          >
            <GmapMarker
              :position="markerCoords"
              :clickable="false"
              :draggable="true"
              @dragend="changeMarkerCoords"
              :icon="{
                url: require('@/assets/icons/MarkerIcon.png'),
                size: {width: 50, height: 50, f: 'px', b: 'px'},
                scaledSize: {width: 50, height: 50, f: 'px', b: 'px'},
              }"
            />
          </GmapMap>
        </div>

        <!-- horarios  -->
        <div class="vx-col lg:w-1/2 sm:w-full sm:mb-10">
          <business-hours
            class="mt-5"
            :selectedTab="selectedTab"
            :fixedHours="fixedHours"
            :specificShedules="specificShedules"
            :holidayHours="holidayHours"
            @selectedTab="selectedTab = $event"
            @fixedHours="fixedHours = $event"
            @specificShedules="specificShedules = $event"
            @holidayHours="holidayHours = $event"
            @hoursErr="hoursErr = $event"
          ></business-hours>
        </div>
                
        <!-- guardar y continuar -->
        <div class="vx-col w-full mt-24" >
          <div class="flex justify-around">
            <vs-button class="rounded-full" @click="saveStore( true )">
              {{ this.$t('Guardar nueva tienda') }}
            </vs-button>

            <vs-button class="rounded-full" type="border" @click="saveStore( false )">
              {{ this.$t('Guardar y agregar nueva tienda') }}
            </vs-button>
          </div>
        </div>
                
      </div>
    </vx-card>
  </div>
</template>

<script lang="ts">
import Vue     from 'vue';
import vSelect from 'vue-select';

import { Validation } from '../../../../plugins/validation.js';

import businessHours from '../BusinessHours.vue';

export default Vue.extend({

  data() {
    return {
      validate    : new Validation(),
      map         : { center: { lat: -15.6248, lng: -58.1843 }, },
      timeout     : null,
      selectedTab : 0,
      fixedHours  : {
        mf: { name: 'Lunes a Viernes', hInit: '08:00:00', hEnd: '21:30:00' },
        sasu: { name: 'Sábados y Domingos', hInit: '08:00:00', hEnd: '18:30:00' },
      },
      specificShedules : [
        { name: 'Lunes',     code: 'M',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Martes',    code: 'TU', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Miércoles', code: 'W',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Jueves',    code: 'TH', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Viernes',   code: 'F',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Sábado',    code: 'SA', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Domingo',   code: 'SU', hInit: '08:00:00', hEnd: '21:00:00' },
      ],
      holidayHours : {
        hInit: '08:00:00',
        hEnd: '13:30:00',
      },
      hoursErr         : false,
      addressValidated : false,
      errs             : {
        minimum_purchase : '',
        phone            : '',
      }
    }
  },

  watch: {
    'store.price': function( val ) {
      // CLEAN STRING AND FORMAT
      const stringCleaned = val = String(val).replace(/\D/g, "");
      const price = stringCleaned === '' ? stringCleaned : Number(stringCleaned).toLocaleString();

      this.store.price = price; // SET Price formated
    },

    'store.coords': function( val ) {
      const coordsStr = val;
      const coordsArr = coordsStr.split(',');
      this.map.center = { lat: parseFloat( coordsArr[0] ), lng: parseFloat( coordsArr[1] ) };
    },

    'store.city_dane': function() {
      clearTimeout( this.timeout );
      this.timeout = setTimeout(() => {
        this.validateAddress();
      }, 10);
    },

    'store.city': function() { this.validateAddress(); },

    'store.type_shops_id'( val ){ this.validate.validateUserType( val ); },

    'store.minimum_purchase'(val) { this.validateMinPrice( parseFloat( val ) ); },
    'store.phone'(val) { this.validatePhone( val ); },
  },

  methods: {
    async validateStoreCode() {            
      const res = await this.$store.dispatch( 'stores/getStoreByCode', this.store.code );
      
      if( res ) {
        this.validate.skuError = this.$t('La tienda ya existe');
        return false;

      } else {
        this.validate.skuError = '';
        return this.validate.validateSKU(this.store.code);

      }
    },

    async validateAddress() {
      const address = this.store.address;
      const dane    = this.store.city_dane;
      const city    = this.cities.find( city => city.dane === dane );
            
      if( address.length < 5 ) {
        this.validate.addressError = this.$t('Ingrese más de 5 caracteres');
        return false;

      } else if( !city ) {
        this.validate.cityError = this.$t('Seleccione una ciudad.');
        return false;

      } else {
        this.validate.addressError = '';
        this.validate.cityError = '';
      }

      const payload = {
        city: city.name,
        address: address,
        geo: 'CODER',
      }
            
            
      const res = await this.$store.dispatch( 'location/getAddresInfo', payload );
      
      if( res ) {
        this.store.coords          = `${res.cy}, ${res.cx}`;
        this.validate.addressError = '';
        this.validate.cityError    = '';

        this.validateAddressStatus( res.estado );
        return true;

      } else {
          this.validate.addressError = this.$t('Seleccione la ubicación en el mapa');
          return false;

      }
    },

    async validateCoords() {
      const coordsStr = this.store.coords;
      const coordsArr = coordsStr.split(',');

      const payload = {
        cx: coordsArr[1],
        cy: coordsArr[0],
        geo: 'INVERSO',
      }
      
      const res = await this.$store.dispatch( 'location/getAddresInfo', payload );

      if( res ) {
        this.store.city_dane  = res.dane1.substring(0,5);
        this.store.address    = res.address1;
        this.addressValidated = true;
        return true;
      }
                    
      return false;
    },

    changeMarkerCoords( coords ) {
      this.store.coords = `${ coords.latLng.lat() }, ${ coords.latLng.lng() }`;
      this.validateCoords(this.store.coords);
    },

    validateAddressStatus( code='' ) {
      const codes  = process.env.VUE_APP_ADDRESS_STATUS_CODES.split(',');
      const finded = codes.find( c => c === code );

      if( finded ) {
        this.setNotification(
          this.$t('No se ha podido localizar la dirección'),
          this.$t('Ubiquela manualmente usando el marcador del mapa de la parte inferior'),
        );
      }     
    },

    async saveStore( go=false ) {
      const isValidName           = this.validate.validateName(this.store.name);
      const isValidCode           = await this.validateStoreCode();
      const isValidAddress        = this.addressValidated ? this.addressValidated : await this.validateAddress();
      const isValidCoverage       = this.validate.validateNumber(this.store.influence);
      const isValidErrandPrice    = this.validate.validateNit(this.store.delivery_price);
      const isValidStoreCategorie = this.validate.validateUserType(this.store.type_shop_id);
      const isValidMinPrice       = this.validateMinPrice( parseFloat( this.store.minimum_purchase ) );
      const isValidPhone          = this.validatePhone( this.store.phone );
            
      if(
        !isValidName ||
        !isValidCode ||
        !isValidAddress ||
        !isValidCoverage ||
        !isValidErrandPrice ||
        this.hoursErr ||
        !isValidStoreCategorie ||
        !isValidMinPrice ||
        !isValidPhone
      ) {
        this.$vs.notify({
          title: this.$t('Verifique los campos antes de continuar'),
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 7000,
        });

        return false;

      }
      
      let schedule = [];

      if( this.selectedTab === 0 ) {
        schedule.push({ end_hour: this.fixedHours.mf.hEnd, init_hour: this.fixedHours.mf.hInit, date_prog: 'M|TU|W|TH|F' });
        schedule.push({ end_hour: this.fixedHours.sasu.hEnd, init_hour: this.fixedHours.sasu.hInit, date_prog: 'SA|SU' });

      } else {
        this.specificShedules.forEach( sch => {
            schedule.push({ end_hour: sch.hEnd, init_hour: sch.hInit, date_prog: sch.code });
        });
      }

      if( this.holidayHours ) schedule.push({ end_hour: this.holidayHours.hEnd, init_hour: this.holidayHours.hInit, date_prog: 'H' });

      const payload = {
          ...this.store,
          scheduling : schedule,
      }

      this.$vs.loading();
      const res = await this.$store.dispatch ('stores/createStore', payload )
      this.$vs.loading.close();

      if ( !res ) {
        this.$vs.notify({
          title: this.$t('No se pudo crear la tienda'),
          text: this.$t('Intente de nuevo más tarde'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 7000,
        });

        return false;
      }
                    
      if( go ) window.location.href = '/app/stores';
      else window.location.href = '/app/stores/new/form';            
    },

    restoreStoreState() {
      this.$store.commit( 'stores/SET_STORE', {
        name           : '',
        code           : '',
        address        : '',
        city           : '',
        influence      : 5,
        cx             : -58.1843,
        cy             : -15.6248,
        coords         : '-58.1843,-15.6248',
        delivery_price : 2500,
        phone          : '',
        type_shops_id  : 1,
      });
    },

    setNotification( title='', text='', time=7000 ) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
        time
      });
    },

    validateMinPrice( val=0 ) {
      if( val < 0 ) this.errs.minimum_purchase = this.$t('Ingrese un valor mayor a cero');
      else this.errs.minimum_purchase = '';
      return this.errs.minimum_purchase.length === 0 ? true : false;   
    },

    validatePhone( phone='' ) {
      if( phone.length === 0 ) {
        this.errs.phone = this.$t('Ingrese un número de teléfono');
        return false;
      }

      if( phone.length < 6 ) {
        this.errs.phone = this.$t('El número de teléfono debe tener 6 dígitos');
        return false;
      }

      this.errs.phone = '';
      return true;
    }
  },
    
  computed: {
    localStore() { return this.$store.state.stores.store; },

    cities() {
      // COPY of cities from vuex store
      const cities = JSON.parse( JSON.stringify( this.$store.state.location.cities ) );
      const citiesOrdered = cities.sort(( a, b ) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          // a must be equal to b
          return 0
      });
      
      return citiesOrdered;
    },

    markerCoords: {
      get() {
        if( this.store.coords ) {
          const coordsStr = this.store.coords;
          const coordsArr = coordsStr.split(',');
          return { lat: parseFloat( coordsArr[0] ), lng: parseFloat( coordsArr[1] ) };

        }
        
        return { lat: this.store.cy, lng: this.store.cx };
      }
    },

    shopCategories() { return this.$store.state.stores.categories; },

    store() {
      const store = this.$store;
      const shop = store.state.stores.store;
      let storeProxy = new Proxy( shop, {
        set (obj, key, value) {
            store.commit('stores/SET_STORE', {
                ...obj,
                [key]: value,
            });    
            return true
        }
      });

      return storeProxy;
    },
  },

  components: {
    'vSelect': vSelect,
    businessHours,
  },

});

</script>
